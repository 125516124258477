import React from "react"
import { Container } from "react-bootstrap"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import About from "../../components/About"
// import Insta from "../components/Insta"

const AboutPage = () => (
  <Layout>
    <SEO title="Sobre" />
    <Container>
      <h3 className="mt-4 cl-vinho">Conheça um pouco sobre mim...</h3>
      <About />
      {/* <Insta /> */}
    </Container>
  </Layout>
)

export default AboutPage
