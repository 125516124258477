import React from "react"
import { Container, Carousel } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SlidesAbout = () => (
  <Container className="w-100">
    <Images />
  </Container>
)

export default SlidesAbout

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      slideImages: allFile(
        filter: { relativeDirectory: { eq: "about" }, extension: {} }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(
                maxWidth: 1200
                maxHeight: 600
                fit: COVER
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Carousel indicators={false} controls={false}>
      {data.slideImages.edges.map(image => (
        <Carousel.Item interval={3000} key={image.node.id}>
          <Img
            fluid={image.node.childImageSharp.fluid}
            alt={image.node.base.split("-").join(" ").split(".")[0]}
            className="rounded-lg"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}
