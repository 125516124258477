import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import SlidesAbout from "../SlidesAbout"
import SocialButtons from "../SocialButtons"

const About = () => {
  return (
    <Container fluid>
      <Row>
        <Col lg={12} className="mt-4">
          <SlidesAbout />
        </Col>

        <Col
          lg={12}
          className="d-flex flex-column justify-content-center align-items-center p-5"
        >
          <p className="cl-marinho">
            Olá! Sou A Mãe Homeschooler, nascida em Juquitiba - SP. Parte da minha
            infância morei em São Lourenço - SP, Cajati - SP e Bariri - SP para
            onde fui aos 9 anos de idade e permaneci até os 25 anos.
            <br />
            <br />
            Bariri é um lugar que tenho afetividade, pois lá mora toda minha
            família (mãe pai e irmãos) e parte dos familiares por parte de pai,
            avós e tias e de onde guardo lindas memórias.
            <br />
            <br />
            Aos 17 anos terminei o ensino médio na escola Ephigênia e por meio
            do vestibular conquistei bolsa integral no curso de Direito. Cursei
            2 anos e meio do curso, e foi nesta época que conheci meu esposo.
            Durante o curso meus objetivos de vida começaram a se alterar e
            percebi que não gostaria de seguir carreira jurídica, na verdade
            naquela época não me via em nenhum lugar do mundo profissional.
            Abandonei a faculdade, não o conhecimento adquirido, acho que foi
            muito útil para mim.
            <br />
            <br />
            Me casei faltando um mês para completar 21 anos, e, esta sim,
            acredito que foi a melhor escolha da minha vida.
            <br />
            <br />
            Logo no início do casamento tivemos nosso primeiro filho, Calebe,
            hoje com quase 6 anos, e depois de 3 anos tivemos a Ana Lívia.
            <br />
            <br />
            Depois do nascimento da minha segunda filha abri mão de trabalhar
            fora para me dedicar exclusivamente à maternidade, e assim tem sido.
            <br />
            <br />
            Mudamos-nos para Santa Catarina no final de 2018, eu, meu esposo e
            filhos; estabelecemos-nos longe de toda a família, começamos nossa
            vida do zero e tudo foi dando certo. Viver longe da família é
            desafiador e às vezes doloroso, mas mesmo com todas as perdas que a
            escolha nos dá, tem sido uma experiência muito boa e de grande
            amadurecimento.
            <br />
            <br />
            No decorrer deste tempo eu já tive a oportunidade de gerenciar uma
            página de maternidade no instagram (Mãe Vida Loka), tive a
            oportunidade de fazer um podcast (Mãe Vida Loka), e agora tenho me
            dedicado ao Canal no Youtube (recém nascido), além do blog é claro.
            Tenho procurado encontrar a mídia digital que mais se adeque a mim.
            Se tem algo que não tenho é medo de arriscar algo novo e novamente
            estou arriscando algo novo. Mas como já sabemos, na vida ninguém faz
            nada sozinho, então, conto com seu apoio aqui no blog e lá no
            youtube para me ajudar nesta empreitada.
            <br />
            <br />
            Estou aberta a sugestões, críticas construtivas e interação, para
            isso basta me seguir nas mídias sociais aqui embaixo, deixar um
            comentário no blog ou me mandar um e-mail que responderei com maior
            prazer.
            <br />
            <br />
            Obrigada por estar aqui! Beijos da Jéh!
          </p>
        </Col>
      </Row>
      <SocialButtons />
    </Container>
  )
}

export default About
